import React, { Component } from 'react';
import { Container, Segment } from 'semantic-ui-react';

class ContentContainer extends Component {
  public render() {
    const { children } = this.props;
    return (
      <Container>
        <Segment vertical>
          {children}
        </Segment>
      </Container>
    );
  }
}

export default ContentContainer;
